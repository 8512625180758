// Auth
import React, { ChangeEventHandler, useEffect, useState } from 'react';
import Input from 'components/common/Input';
import styles from './Auth.module.sass';
import Loader from 'components/common/Loader';
import { useAuth } from 'hooks/useAuth';
import ContentExamples from './ContentExamples';
import { API_RESPONSE_STATUS, AUTH_STATUS } from 'types/auth';
import { getAuthRedirect } from 'components/containers/AuthProtection';
import { useRouter } from 'next/router';
import ROUTES from 'config/routes';
import { getLocalImageUrl } from 'utils/image';
import { validateEmail, validatePasswordOrReturnError } from 'utils/validators';
import useToast from 'components/common/Toast';
import { passwordResetEmail } from 'services/firebaseAuth';
import { TermsAndPolicy } from './TermsAndPolicy';
import { GoogleSSOauth } from './GoogleSSOauth';

export interface AuthProps {
  initialFormType: AuthFormType;
}

type AuthFormType = 'login' | 'sign-up' | 'forgot-password';

const FORM_STATE = {
  login: {
    title: 'Welcome Back,',
    subtitle: 'Pair up with creators across the country',
    actionButtonText: 'LOGIN',
    toggleText: "Don't have an account?",
    toggleButtonText: 'Sign Up',
  },
  'sign-up': {
    title: 'Start your free content brief',
    subtitle: 'Create an account below',
    actionButtonText: 'SIGN UP',
    toggleText: 'Already have an account?',
    toggleButtonText: 'Login',
  },
  'forgot-password': {
    title: 'Enter your email below to reset your password. Check your inbox.',
    subtitle: '',
    actionButtonText: 'RESET PASSWORD',
    toggleText: '',
    toggleButtonText: '',
  },
} as const;

const AuthView: React.FC<AuthProps> = ({ initialFormType }) => {
  const router = useRouter();

  const [authFormType, setAuthFormType] =
    useState<AuthFormType>(initialFormType);

  const {
    authState: { status: authStatus, brand, error: authError },
    login,
    googleLogin,
    signup,
  } = useAuth();
  const { triggerToast } = useToast();
  const [{ email, password }, setAuthForm] = useState({
    email: '',
    password: '',
  });
  const [emailError, setEmailError] = useState<string | undefined>();
  const [passwordError, setPasswordError] = useState<string | undefined>();
  const [emailSendNote, setEmailSendNote] = useState<string | void>('');

  useEffect(() => {
    if (authStatus === AUTH_STATUS.AUTHENTICATED && brand) {
      const redirect = getAuthRedirect() || ROUTES.ShowCase.getPath();
      if (!brand.firstCampaign) {
        // If brand has not created 1st campaign, push to Create Campaign Paywall
        router.push({
          pathname: ROUTES.CreateCampaign.getPath(),
        });
      } else {
        router.push({
          pathname: redirect,
          query: `latest_campaign`,
        });
      }
    }
  }, [authStatus, brand, router]);

  useEffect(() => {
    if (authError) {
      const emailErrorRegex = /(user)|(email)/gi;
      if (emailErrorRegex.test(authError)) {
        setEmailError(authError);
      } else {
        setPasswordError(authError);
      }
    }
  }, [authError]);

  const handleEmailAuth = async () => {
    if (authFormType === 'forgot-password') {
      setEmailSendNote(await passwordResetEmail(email));
    } else if (authFormType === 'sign-up') {
      handleSignUp();
    } else {
      handleLogin();
    }
  };

  const onInputChange: ChangeEventHandler<HTMLInputElement> = ({
    target: { id, value },
  }) => {
    setAuthForm((form) => ({ ...form, [id]: value }));
    if (id === 'email') {
      setEmailError(undefined);
    } else if (id === 'password') {
      setPasswordError(undefined);
    }
  };

  const checkErrors = () => {
    const isValidEmail = validateEmail(email);
    setEmailError(isValidEmail ? undefined : 'Invalid email');
    const isValidPasswordOrError = validatePasswordOrReturnError(password);
    setPasswordError(
      typeof isValidPasswordOrError === 'string'
        ? isValidPasswordOrError
        : undefined
    );
    return isValidEmail && typeof isValidPasswordOrError !== 'string';
  };

  const handleGoogleSSO = async () => {
    const { status, message } = await googleLogin();
    if (status === API_RESPONSE_STATUS.ERROR) {
      setEmailError(message);
      triggerToast(message);
    }
  };

  const handleLogin = () => {
    if (checkErrors()) {
      login(email, password);
    }
  };

  const handleSignUp = () => {
    if (checkErrors()) {
      signup(email, password);
    }
  };

  const toggleFormType = () => {
    setAuthFormType((type) => (type === 'sign-up' ? 'login' : 'sign-up'));
  };

  const forgotPass = () => setAuthFormType('forgot-password');

  const handleEmailKeyUp: React.KeyboardEventHandler<HTMLInputElement> = ({
    key,
  }) => {
    if (key === 'Enter' || key === 'Tab') {
      document.getElementById('password')?.focus();
    }
  };

  const handlePasswordKeyUp: React.KeyboardEventHandler<HTMLInputElement> = ({
    key,
  }) => {
    if (key === 'Enter') {
      if (authFormType === 'sign-up') {
        handleSignUp();
      } else {
        handleLogin();
      }
    }
  };

  return (
    <div className={styles.authView}>
      <ContentExamples />
      <div className={styles.authContainer}>
        <div className={styles.authForm}>
          <div className={styles.titleLogo}>
            <img
              src={getLocalImageUrl('/images/trend-logo-black.svg')}
              alt="trend-logo"
            />
          </div>
          <h3>
            {emailSendNote ? emailSendNote : FORM_STATE[authFormType].title}
            {authFormType === 'login' && (
              <>
                <br />
                Log In
              </>
            )}
          </h3>
          <p className="text-size-regular">
            {FORM_STATE[authFormType].subtitle}
          </p>
          <div className={styles.inputContainer}>
            {authStatus === 'loading' || authStatus === 'authenticated' ? (
              <div className={styles.loadingContainer}>
                <Loader />
              </div>
            ) : (
              <>
                <GoogleSSOauth
                  isGoogleAuth={authFormType !== 'forgot-password'}
                  handleGoogleSSO={handleGoogleSSO}
                  styles={styles}
                />
                <Input
                  type="email"
                  id="email"
                  placeholder={
                    authFormType === 'login' ? 'Login Email' : 'Company Email'
                  }
                  wrapperClassName={styles.inputWrapper}
                  value={email}
                  onChange={onInputChange}
                  error={emailError}
                  onKeyUp={handleEmailKeyUp}
                  showErrorText
                />
                {authFormType !== 'forgot-password' && (
                  <Input
                    type="password"
                    id="password"
                    placeholder="Password"
                    wrapperClassName={styles.inputWrapper}
                    value={password}
                    onChange={onInputChange}
                    onKeyUp={handlePasswordKeyUp}
                    showErrorText
                    error={passwordError}
                  />
                )}
                {authFormType === 'login' && (
                  <p onClick={forgotPass} className={styles.forgotPassword}>
                    Forgot Password?
                  </p>
                )}
                <button
                  className="button-3d full-width"
                  onClick={handleEmailAuth}
                  disabled={
                    authFormType !== 'forgot-password'
                      ? Boolean(
                          !email || !password || emailError || passwordError
                        )
                      : Boolean(!email)
                  }
                >
                  {FORM_STATE[authFormType].actionButtonText}
                </button>
              </>
            )}
          </div>
          <div className={styles.footerContent}>
            {authFormType === 'sign-up' && <TermsAndPolicy />}
            <p className="text-size-regular text-align-center text-lineheight-normal">
              {FORM_STATE[authFormType].toggleText}{' '}
              <a onClick={toggleFormType}>
                {FORM_STATE[authFormType].toggleButtonText}
              </a>
            </p>
          </div>
        </div>
        <div className={styles.creatorAuth}>
          <p className="text-size-regular text-lineheight-normal text-align-center">
            Want to join as a creator?{' '}
            <a
              href="https://creators.trend.io"
              className="DM-Sans text-weight-bold"
            >
              Sign Up Here
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default AuthView;
