// ContentExamples
import { EXAMPLE_ASSETS } from 'constants/auth';
import React from 'react';
import { getLocalImageUrl } from 'utils/image';
import styles from './ContentExamples.module.sass';

const ContentExamples: React.FC = () => {
  return (
    <div className={styles.contentExamples}>
      <img
        className={styles.logo}
        src={getLocalImageUrl('images/trend-logo-white.svg')}
        alt="trend-logo"
      />
      <div className={styles.carousel}>
        {EXAMPLE_ASSETS.map((content) => (
          <ContentExample {...content} key={content.company} />
        ))}
      </div>
    </div>
  );
};

export interface ContentExampleProps {
  imgSrc: string;
  contentType: string;
  company: string;
}

const ContentExample: React.FC<ContentExampleProps> = ({ imgSrc }) => {
  return (
    <div
      className={styles.contentExample}
      style={{ backgroundImage: `url(${getLocalImageUrl(imgSrc)})` }}
    >
      <div className={styles.gradient}>
        <div className={styles.footerRow}>
          <img
            src={getLocalImageUrl('images/icons/soona-wink-orange.svg')}
            alt="trend-logo"
          />
          <p>User Generated Content at soona is powered by Trend</p>
        </div>
      </div>
    </div>
  );
};

export default ContentExamples;
